import React, { useEffect, useRef } from 'react'
import CaseStudyLayout from '../../../components/caseStudyLayout'
import * as styles from './styles.module.css'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import challenge from './img/challenge.png'
import intro from './img/intro.png'
import results from './img/results.png'
import solutionLeft from './img/solution_left.png'
import solutionRight from './img/solution_right.png'
import marty from './img/martyjertson.png'

const Ballnamic = ({ data }) => {
  return (
    <>
      <CaseStudyLayout {...data}>
        <Helmet bodyAttributes={{ class: styles.ballnamicBody }} />

        <div className={styles.ballnamic}>
          <section className={styles.hero}>
            <div className={styles.container}>
              <div className={styles.heroContent__wrapper}>
                <div className={styles.logoTitle__wrapper}>
                  <div className={styles.heroLogo}>
                    <svg
                      viewBox="0 0 361 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M91.967 95.135a42.308 42.308 0 0 1-32.98-16.112h-6.069a46.806 46.806 0 0 0 78.068 0h-6.04a42.307 42.307 0 0 1-32.979 16.112ZM91.967 4.885c12.877.062 25.022 6 32.979 16.126h6.04a46.791 46.791 0 0 0-78.097 0h6.07A42.207 42.207 0 0 1 91.966 4.885ZM47.752 33.95H17.406l-1.091 5.413h47.184l1.092-5.414H47.75ZM45.409 45.84H1.092L0 51.24H61.157l1.09-5.4H45.41ZM42.963 57.716H22.515l-1.092 5.4h37.302l1.077-5.4H42.963Z"
                        fill="#5057FF"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M108.719 50.934a7.913 7.913 0 0 1 1.354 4.614 10.188 10.188 0 0 1-4.905 9.125 23.534 23.534 0 0 1-12.808 3.13H71.111L78.84 29.16h19.532c4.347 0 7.67.781 9.97 2.343a7.643 7.643 0 0 1 3.449 6.71 9.54 9.54 0 0 1-1.819 5.821 12.07 12.07 0 0 1-5.08 3.843 8.729 8.729 0 0 1 3.828 3.056Zm-11.207 7.874a3.915 3.915 0 0 0 1.66-3.42 2.75 2.75 0 0 0-1.208-2.46 5.953 5.953 0 0 0-3.319-.8h-9.62l-1.557 7.83h9.504a7.83 7.83 0 0 0 4.54-1.15ZM86.597 44.559l1.455-7.451h7.7c3.128 0 4.584 1.019 4.584 3.042a3.873 3.873 0 0 1-1.456 3.289 7.496 7.496 0 0 1-4.453 1.12h-7.83ZM143.299 67.86l5.967-29.923h-10.45l-.713 3.595c-1.766-2.727-4.822-4.09-9.169-4.09a15.351 15.351 0 0 0-8.135 2.27 16.254 16.254 0 0 0-6.026 6.215 17.914 17.914 0 0 0-2.183 8.805c-.057 2.54.555 5.05 1.776 7.277a12.383 12.383 0 0 0 4.657 4.716 12.59 12.59 0 0 0 6.215 1.63 12.299 12.299 0 0 0 8.732-3.318l-.597 2.823h9.926Zm-7.233-16.286a8.91 8.91 0 0 1-1.98 6.011 6.476 6.476 0 0 1-5.079 2.329 5.256 5.256 0 0 1-5.676-5.691 8.99 8.99 0 0 1 1.979-6.069 6.477 6.477 0 0 1 5.138-2.329 5.56 5.56 0 0 1 4.162 1.558 5.678 5.678 0 0 1 1.456 4.191Z"
                        fill="#303133"
                      />
                      <path
                        d="M156.82 26.905h10.552l-8.165 40.955h-10.494l8.107-40.955ZM172.713 26.905h10.493l-8.165 40.955h-10.493l8.165-40.955ZM212.984 40.062a9.563 9.563 0 0 1 2.911 7.539 18.084 18.084 0 0 1-.393 3.697l-3.319 16.562h-10.479l3.086-15.573a8.73 8.73 0 0 0 .218-1.98c0-2.648-1.368-3.972-4.133-3.972a6.304 6.304 0 0 0-4.483 1.688 9.549 9.549 0 0 0-2.488 5.05l-2.911 14.802H180.47l5.967-29.923h9.926l-.597 3.1a12.292 12.292 0 0 1 4.367-2.708 14.363 14.363 0 0 1 5.021-.887 11.455 11.455 0 0 1 7.83 2.605Z"
                        fill="#303133"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="m249.544 67.86 5.967-29.923h-10.45l-.713 3.595c-1.776-2.727-4.832-4.09-9.169-4.09a15.355 15.355 0 0 0-8.136 2.27 16.258 16.258 0 0 0-6.025 6.215 17.914 17.914 0 0 0-2.183 8.805c-.058 2.54.555 5.05 1.775 7.277a12.28 12.28 0 0 0 4.658 4.716 12.584 12.584 0 0 0 6.214 1.63 12.301 12.301 0 0 0 8.733-3.318l-.612 2.823h9.941Zm-7.234-16.286a8.904 8.904 0 0 1-1.979 6.011 6.476 6.476 0 0 1-5.079 2.329 5.285 5.285 0 0 1-5.691-5.691 8.996 8.996 0 0 1 1.994-6.07 6.461 6.461 0 0 1 5.137-2.328 5.575 5.575 0 0 1 4.163 1.558 5.734 5.734 0 0 1 1.455 4.191Z"
                        fill="#303133"
                      />
                      <path
                        d="M306.726 40.091a9.72 9.72 0 0 1 2.911 7.51 18.014 18.014 0 0 1-.393 3.697l-3.245 16.562h-10.552l3.1-15.47c.149-.69.227-1.392.233-2.096a4.135 4.135 0 0 0-.975-2.911 3.668 3.668 0 0 0-2.78-.99c-3.348 0-5.472 2.154-6.346 6.448l-3.041 15.078h-10.479l3.143-15.53a9.079 9.079 0 0 0 .219-2.052 4.15 4.15 0 0 0-.976-2.998 3.622 3.622 0 0 0-2.779-1.019c-3.391 0-5.516 2.184-6.404 6.564l-2.911 14.962h-10.479l5.953-29.923h9.94l-.611 3.041a12.487 12.487 0 0 1 8.951-3.536 11.466 11.466 0 0 1 5.341 1.237 8.601 8.601 0 0 1 3.624 3.639 14.185 14.185 0 0 1 4.876-3.625 15.074 15.074 0 0 1 6.098-1.237 10.827 10.827 0 0 1 7.582 2.65Z"
                        fill="#303133"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M323.769 33.745a5.152 5.152 0 0 0 5.152-5.152 5.137 5.137 0 0 0-5.152-5.152 5.152 5.152 0 0 0 0 10.304Zm3.813 4.192h-10.435l-5.938 29.923h10.435l5.938-29.923Z"
                        fill="#303133"
                      />
                      <path
                        d="M335.442 66.667a12.832 12.832 0 0 1-5.516-4.774 13.1 13.1 0 0 1-1.951-7.175 16.521 16.521 0 0 1 8.995-15.005 19.894 19.894 0 0 1 9.547-2.27 17.465 17.465 0 0 1 8.733 2.124 12.632 12.632 0 0 1 5.399 5.996l-8.936 4.25a5.822 5.822 0 0 0-5.822-3.988 6.767 6.767 0 0 0-5.268 2.33 8.57 8.57 0 0 0-2.067 5.952 5.372 5.372 0 0 0 5.822 5.822 7.277 7.277 0 0 0 3.711-.947A7.755 7.755 0 0 0 350.84 56l7.67 4.642a14.774 14.774 0 0 1-6.04 5.676 18.587 18.587 0 0 1-8.718 2.082 19.338 19.338 0 0 1-8.31-1.732Z"
                        fill="#303133"
                      />
                    </svg>
                  </div>
                  <h1 className={styles.heroTitle}>
                    The best tool for&nbsp;
                    <br />
                    revamping your game
                  </h1>
                </div>
                <div className={styles.servicesBreakdown__wrapper}>
                  <div className={styles.servicesOffered}>
                    <h4>Services Offered</h4>
                    <ul>
                      <li>Product Design</li>
                      <li>Web App Development</li>
                      <li>Ongoing Production Support</li>
                      <li>Fully Integrated Product Team</li>
                    </ul>
                  </div>
                  <div className={styles.solutionsBuilt}>
                    <h4>Solutions Built</h4>
                    <ul>
                      <li>Golf Ball Fitting Web App</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className={`${styles.contentSection} ${styles.contentSection__horizontal} ${styles.introduction}`}
          >
            <div className={styles.container}>
              <div className={styles.contentSectionInner}>
                <div className={styles.contentText}>
                  <h2 className={styles.contentTextTitle}>
                    Introducing Golf Ball Fitting to the Golf Industry
                  </h2>
                  <p className={styles.contentTextBody}>
                    Like many other innovations they’re responsible for in the
                    golf industry, PING came to Synapse to help them bring their
                    proprietary custom golf ball fitting technology to market.
                    Together, we launched PING’s Ballnamic golf ball fitting
                    tool that promises to level up anyone’s golf game. Using
                    custom aerodynamics modeling, simulation technology, and
                    PING developed algorithms used to test virtually every ball
                    on the market annually, fittings can now be performed with
                    accurate matches based on the golfer’s individual driver,
                    iron, wedge, and putter preferences.
                  </p>
                </div>
                <img src={intro} alt="Introduction" />
              </div>
            </div>
          </section>

          <section
            className={`${styles.contentSection} ${styles.contentSection__horizontal} ${styles.challenge}`}
          >
            <div className={styles.container}>
              <div className={styles.contentSectionInner}>
                <div className={styles.challengeGraphic__wrapper}>
                  <img src={challenge} alt="Challenge" />
                  <div className={styles.ballStatsList}>
                    <div className={styles.ballStat}>
                      <div className={styles.statLabel}>
                        Current Driver Distance
                      </div>
                      <div className={styles.statValue}>223 yds</div>
                    </div>
                    <div className={styles.ballStat}>
                      <div className={styles.statLabel}>
                        Current Driver Height
                      </div>
                      <div className={styles.statValue}>140 yds</div>
                    </div>
                    <div className={styles.ballStat}>
                      <div className={styles.statLabel}>Current Iron Carry</div>
                      <div className={styles.statValue}>146 yds</div>
                    </div>
                    <div className={styles.ballStat}>
                      <div className={styles.statLabel}>Current Iron Roll</div>
                      <div className={styles.statValue}>140 yds</div>
                    </div>
                  </div>
                </div>
                <div className={styles.contentText}>
                  <h2 className={styles.contentTextTitle}>
                    Optimizing the Golf Ball Trajectory Algorithm
                  </h2>
                  <p className={styles.contentTextBody}>
                    Historically, golfers have made golf ball selections based
                    off recommendations from friends or based on loyalty to
                    certain brands. Just like any other aspect of the golf game,
                    there are so many variables that play a role in the physics
                    of a golf ball flying through the air and how it will affect
                    any one individual’s performance. Over the years, PING had
                    developed a model that charts the custom path each ball
                    travels, considering when and how someone plays, and had
                    rigorously tested this model to produce a Tour-inspired
                    algorithm ready for broader use outside the walls of the
                    business.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section
            className={`${styles.contentSection} ${styles.contentSection__vertical}`}
          >
            <div className={styles.container}>
              <div className={styles.contentSectionInner}>
                <div className={styles.contentText}>
                  <h2 className={styles.contentTextTitle}>
                    Bringing Golf Ball Fittings to Market
                  </h2>
                  <p className={styles.contentTextBody}>
                    Synapse and PING worked together to craft a web application
                    that allows golfers and fitters alike to supply game metrics
                    such as current ball used, temperature and altitude of play,
                    ball speed, and others to receive a custom ball fitting
                    report. The are tailored to the player with recommendations
                    designed to increase the customer’s performance in key areas
                    specified during the fitting.
                  </p>
                </div>
              </div>
              <div className={styles.compareWrapper}>
                <div className={`${styles.compare} ${styles.compareLeft}`}>
                  <h3>Tailor your results</h3>
                  <div className={styles.compareImg__wrapper}>
                    <img src={solutionLeft} alt="Tailor your results" />
                  </div>
                </div>
                <div className={`${styles.compare} ${styles.compareRight}`}>
                  <h3>Key golf ball stats at your disposal</h3>
                  <img
                    src={solutionRight}
                    alt="Key golf ball stats at your disposal"
                  />
                </div>
              </div>
            </div>
          </section>

          <section
            className={`${styles.contentSection} ${styles.contentSection__horizontal} ${styles.results}`}
          >
            <div className={styles.container}>
              <div className={styles.contentSectionInner}>
                <div className={styles.contentText}>
                  <h1 className={styles.contentTextTitle}>Results</h1>
                  <ul>
                    <li>
                      Over <strong>29,000</strong> players have completed a
                      Ballnamic fitting from the comfort of their own home
                    </li>
                    <li>
                      Over <strong>7,000</strong> Ballnamic fittings have been
                      performed by a professional fitter
                    </li>
                    <li>
                      Launched originally in 2021, adoption of the Ballnamic
                      fitting tool has continued to grow as it becomes an
                      important extension to PING’s ecosystem of cutting edge
                      fitting tools
                    </li>
                  </ul>
                </div>
                <img src={results} alt="Results" />
              </div>
            </div>
          </section>

          <section
            className={`${styles.contentSection} ${styles.contentSection__vertical} ${styles.testimonials}`}
          >
            <div className={styles.container}>
              <div className={styles.testimonialList__wrapper}>
                <div className={styles.testimonialCard}>
                  <h2>
                    Hear It From Our Client
                  </h2>
                  <div className={styles.testimonialImage}>
                    <img src={marty} alt="Marty Jertson, VP Fitting & Performance. PING." />
                  </div>
                  <figure>
                    <blockquote>
                      <p>
                        At PING, we had a dream to build the first ever brand-agnostic, physics-based golf ball custom fitting solution for the marketplace. We had an unpolished internal prototype that we used to vet the modeling and algorithms... and a rough idea of business model... but we were lacking a beautiful UI and stable App solution to deliver them to our customers.
                        <br />
                        <br />
                        We needed a totally custom solution to innovate in this space. And that's where Synapse came in.
                        <br />
                        <br />
                        Not only did they help us build a beautiful solution, they helped us define and refine the project requirements and the business needs and product strategy.  We have loved working with Synapse for their "small team" feel that is uncommon to find with a software partner. Their bench is very deep with technical expertise, from superb designers to highly skilled front and back end development, all the way through testing and live support.
                        <br />
                        <br />
                        And that doesn't consider the transparent communication through their Product Management team. We look forward to building more innovative solutions with Synapse in the future.
                      </p>
                    </blockquote>
                    <figcaption>
                      <cite>
                        <span>Marty Jertson</span>
                        <span>VP Fitting & Performance &mdash; PING</span>
                      </cite>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </section>
        </div>
      </CaseStudyLayout>
    </>
  )
}

export default Ballnamic

export const query = graphql`
  query ballnamicCaseStudyQuery {
    caseStudy: contentfulCaseStudy(slug: { eq: "ballnamic-by-ping" }) {
      seoTitle
      slug
      title
      excerpt
    }
    caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
      ...pageFields
    }
    workPage: contentfulPage(name: { eq: "page.work" }) {
      workPageSlug: slug
    }
  }
`
