// extracted by mini-css-extract-plugin
export var ballStat = "styles-module--ball-stat--5680f";
export var ballStatsList = "styles-module--ball-stats-list--05054";
export var ballnamic = "styles-module--ballnamic--2054b";
export var ballnamicBody = "styles-module--ballnamicBody--96bf8";
export var challenge = "styles-module--challenge--30946";
export var challengeGraphic__wrapper = "styles-module--challenge-graphic__wrapper--1cfe9";
export var compare = "styles-module--compare--469d2";
export var compareImg__wrapper = "styles-module--compare-img__wrapper--46398";
export var compareLeft = "styles-module--compare-left--73b12";
export var compareWrapper = "styles-module--compare--wrapper--37738";
export var container = "styles-module--container--9d5fb";
export var contentImage = "styles-module--content-image--5ea0d";
export var contentSection = "styles-module--content-section--ffb13";
export var contentSectionInner = "styles-module--content-section--inner--2f7ee";
export var contentSection__horizontal = "styles-module--content-section__horizontal--8743a";
export var contentSection__vertical = "styles-module--content-section__vertical--31116";
export var contentText = "styles-module--content-text--1ecc4";
export var contentTextTitle = "styles-module--content-text--title--d001a";
export var hero = "styles-module--hero--c80ce";
export var heroContent__wrapper = "styles-module--hero--content__wrapper--0d35f";
export var heroLogo = "styles-module--hero-logo--ed55a";
export var heroTitle = "styles-module--hero-title--f83d8";
export var introduction = "styles-module--introduction--b303f";
export var logoTitle__wrapper = "styles-module--logo-title__wrapper--de888";
export var results = "styles-module--results--99ffa";
export var servicesBreakdown__wrapper = "styles-module--services-breakdown__wrapper--751fb";
export var servicesOffered = "styles-module--services-offered--c3cc9";
export var solutionsBuilt = "styles-module--solutions-built--d68c8";
export var statLabel = "styles-module--stat-label--df3e0";
export var statValue = "styles-module--stat-value--47c55";
export var testimonialCard = "styles-module--testimonial-card--108a2";
export var testimonialImage = "styles-module--testimonial-image--6bf36";
export var testimonialList__wrapper = "styles-module--testimonial-list__wrapper--b0032";
export var testimonials = "styles-module--testimonials--41144";